<template>
  <div></div>
</template>

<script>
import axios from 'axios'
import LiffChecker from "@/utils/LiffChecker";
// import liff from "@line/liff";

export default {
  mounted() {
    this.fetchFile()
  },
  methods: {
    async sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    handleFileUrl(url, name = false) {
      if (!name) {
        name = url.split('/').pop();
      }
      // 使用 axios 請求 Blob 資料 避免瀏覽器預覽顯示出亂碼名
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob'
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: response.data.type });
        const link = document.createElement('a');
        const fileUrl = window.URL.createObjectURL(blob);
        link.href = fileUrl;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        // liff.closeWindow();
      })
      .catch((error) => {
        console.error('Download error:', error);
      });
    },
    async fetchFile() {
      let { org_code: orgCode, uploadFile: fileId } = this.$route.params
      let url = process.env.VUE_APP_API_BASE_URL + "/" + orgCode + "/liff/upload-file/" + fileId + "/download";
      try {
        const { data } = await axios.get(url)
        this.handleFileUrl(data.url, data.file_name)
        if (data.is_friend) {
          const checker = new LiffChecker(orgCode);
          await checker.initLiff()
          await this.sleep(1000)
          this.handleFileUrl(data.url, data.file_name)
        } else {
          this.handleFileUrl(data.url, data.file_name)
        }
      } catch (error) {
        console.log(error)
        this.$swal.fire({
          title: "Error",
          text: "檔案已失效",
          icon: "error",
          confirmButtonText: "返回"
        }).then(() => {
          this.$router.go(-1)
        })
      }
    }
  }
}
</script>
